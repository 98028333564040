import { httpFetch } from "../../Utils/httpUtils";
import {packageActionTypes  as actionTypes} from "../../ActionTypes/Voss/packageActionTypes";

const packageActions = {

    getPackages: () => {
        return (dispatch) => {
            return httpFetch(`/api/packages/GetPackages`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_PACKAGES, data: json });
                })
        }
    },

    getPackage: (id) => {
        return (dispatch) => {
            return httpFetch(`/api/packages/GetPackage/${id}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_PACKAGE, data: json });
                })
        }
    },

    clear: (key) => {
        return (dispatch) => {
            dispatch({ type: actionTypes.CLEAR, key });
        }
    },

    getPackageNames: () => {
        return (dispatch) => {
            return httpFetch(`/api/packages/GetPackageNames`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_PACKAGE_NAMES, data: json });
                })
        }
    }
};

export default packageActions;
