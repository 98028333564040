import React from 'react';
import { connect } from 'react-redux';

import * as calcTypes from '../../Constants/priceCalculationTypes';
import Grid from "../../Components/Grid/Grid";
import { VbProductOptionsCard } from '../../Components/OrderPlacement/ProductComponents/Vb/VbProductOptionsCard';
import { bindActionCreators } from 'redux';
import orderActions from '../../Actions/orderActions';
import commonActions from '../../Actions/commonActions';
import { VbDiscountOptionsCard } from '../../Components/OrderPlacement/ProductComponents/Vb/VbDiscountOptionsCard';
import { VbProductSummaryCard } from '../../Components/OrderPlacement/ProductComponents/Vb/VbProductSummaryCard';

const VbProductContainer = ({ ...props }) => {

  return (
    <>
      <Grid.Row>
        <Grid.Row.Column width={4}>
          <VbProductOptionsCard {...props} />
        </Grid.Row.Column>
        <Grid.Row.Column width={4}>
          <VbDiscountOptionsCard {...props} />
        </Grid.Row.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Row.Column>
          <VbProductSummaryCard {...props} priceCalcType={calcTypes.priceCalculationTypes.order} />
        </Grid.Row.Column>
      </Grid.Row>
    </>
  );
};


const mapDispatchToProps = (dispatch) => ({
  orderActions: bindActionCreators(orderActions, dispatch),
  commonActions: bindActionCreators(commonActions, dispatch)
});

const mapStateToProps = (state) => ({
  order: state.OrderReducer.order,
  vbCustomer: state.CustomerReducer.selectedCustomer,
  user: state.CommonReducer.user,
  product: state.OrderReducer.product && state.OrderReducer.product,
  editOrderline: state.OrderReducer.editOrderline,
});


export default connect(mapStateToProps, mapDispatchToProps)(VbProductContainer);
