import React, { Component, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomerProducts from '../../Components/Order/CustomerProducts';
import CustomerInfo from '../../Components/CustomerInfo';
import TextInput from '../../Components/TextInput'
import TextInputWithButton from '../../Components/TextInputWithButton'
import Loader from '../../Components/Loader'
import routes from '../../Constants/routes'
import DateTimeInput from "../../Components/DateTimeInput"
import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'
import helpers from '../../Utils/helpers'
import DropDown from "../../Components/DropDown";
import Panel, {PanelGrid} from '../../Components/Panel';

function ConsultingContainer({ customerActions, consultingProducts, commonActions, consultingForm, vbCustomer, customer, user, t }) {

    const navigate = useNavigate();

    useEffect(() => {
        customerActions.createConsultingForm(createConsultingFormData());
        if (!consultingProducts)
            commonActions.loadDropdownList("ConsultingProducts")
    }, [])

    const handleClickCancel = () => {
        commonActions.setOpenHeader('main')
        commonActions.cancelFormEditing(consultingForm.id, () => navigate(routes.customerMain(customer.pathId)))
    }

    const routeBack = () => {
        return () => navigate(routes.customerMain(customer.pathId))
    }

    const handleClickSave = () => {
        customerActions.saveConsulting(consultingForm, vbCustomer.customerNumber, routeBack());
    }

    const addContactPerson = (customer) => {
        onFieldChange('reference', customer.contactPerson)
        onFieldChange('attendeeEmail', customer.emailAddress)

    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(consultingForm.id, field, value)
    }

    const onPriceChange = (value) => {
        let total = parseFloat(value) || 0
        commonActions.changeMultiFormField(consultingForm.id, "price", value)

        if (consultingForm.data.product.priceType === priceTypes.HourlyPrice ||
            consultingForm.data.product.priceType === priceTypes.OnboardingHourly)
            total = parseFloat(value * consultingForm.data.hoursCharged) || 0

        if (consultingForm.data.product.priceType === priceTypes.OnboardingTravel ||
            consultingForm.data.product.priceType === priceTypes.OnboardingTravelVariable)
            total += parseFloat(consultingForm.data.travelCost) || 0


        commonActions.changeMultiFormField(consultingForm.id, "totalCost", total)
    }

    const onTravelCostChange = (value) => {
        const price = parseFloat(consultingForm.data.price) || 0
        let total = (parseFloat(value) || 0) + price
        commonActions.changeMultiFormField(consultingForm.id, "travelCost", value)
        commonActions.changeMultiFormField(consultingForm.id, "totalCost", total)
    }

    const onHoursFieldChange = (value) => {
        const formData = consultingForm.data

        if (value) {
            customerActions.getConsultingTotalCost(consultingForm.data.product.id, value, formData.price)
        }

        commonActions.changeMultiFormField(consultingForm.id, "hoursCharged", value)
    }

    const createConsultingFormData = () => {
        return {
            salesforceTicketId: "",
            hoursCharged: "",
            totalCost: "",
            reference: "",
            price: 0,
            productId: 0,
            travelCost: 0,
            salesNumber: user.salesNumber,
            attendeeEmail: "",
            discount: null
        }
    }

    const onProductSelect = (id) => {
        setPartnerDiscount(vbCustomer.partnerLevel)
        commonActions.changeMultiFormField(consultingForm.id, "productId", id)
        commonActions.changeMultiFormField(consultingForm.id, "hoursCharged", 0)
        commonActions.changeMultiFormField(consultingForm.id, "price", 0)
        commonActions.changeMultiFormField(consultingForm.id, "travelCost", 0)
        if (id > 0)
            customerActions.getConsultingProduct(id)
    }

    const setOnlineBooking = (field, value) => {
        commonActions.changeMultiFormField(consultingForm.id, field, value)
    }

    const renderInputControls = () => {
        const formData = consultingForm.data
        if (!formData || !formData.product)
            return null;
        const product = formData.product

        let salesforceTicket = null;
        if (product.requireSalesforceTicket)
            salesforceTicket = <TextInput label="Salesforce Ticket Id *" value={formData.salesforceTicketId}
                onChange={onFieldChange.bind(this, "salesforceTicketId")} />

        let salesNumber = null;

        const salesNumberInputProps = {
            buttonTitle: t('consultingcontainer.onlinebooking'),
            label: t('consultingcontainer.salesnumber'),
            value: formData.salesNumber,
            onChange: onFieldChange.bind(this, 'salesNumber'),
            buttonClick: () => setOnlineBooking('salesNumber', 242)
        }

        let certificateInfo = null;

        if (product.sendCertificate) {
            certificateInfo =
                <Fragment>
                    <TextInput label={t('consultingcontainer.lengthinhours')} value={formData.lengthInHours} onChange={onFieldChange.bind(this, "lengthInHours")} />
                    <TextInput label={t('consultingcontainer.coursecoordinator')} value={formData.courseCoordinator} onChange={onFieldChange.bind(this, "courseCoordinator")} />
                    <DateTimeInput label={t('consultingcontainer.coursedate')} dateFormat={true} showTime={false} value={formData.courseDate} onChange={onFieldChange.bind(this, "courseDate")} />
                </Fragment>
        }

        if (product.priceType >= priceTypes.OnboardingFixed)
            salesNumber =
                <div >
                    <TextInputWithButton {...salesNumberInputProps} />
                </div>

        switch (product.priceType) {
            case priceTypes.FixedPrice:
                return <div style={{ marginBottom: '6px' }}>
                    {salesforceTicket}
                    <TextInput label={t('consultingcontainer.price')} value={formData.totalCost}
                        onChange={onPriceChange.bind(this)} />
                    {certificateInfo}
                </div>

            case priceTypes.VariablePrice:
                return <div style={{ marginBottom: '6px' }}>
                    {salesforceTicket}
                    <TextInput label={t('consultingcontainer.price')} value={formData.totalCost}
                        onChange={onPriceChange.bind(this)} />
                    {certificateInfo}
                </div>

            case priceTypes.HourlyPrice:
                return <div style={{ marginBottom: '6px' }}>
                    {salesforceTicket}
                    <TextInput label={t('consultingcontainer.hours')} value={formData.hoursCharged}
                        onChange={onHoursFieldChange.bind(this)} />
                    <TextInput label={t('consultingcontainer.hourlyprice')} value={formData.price}
                        onChange={onPriceChange.bind(this)} />
                    {certificateInfo}
                </div>
            case priceTypes.OnboardingHourly:
                return <div style={{ marginBottom: '6px' }}>
                    {salesforceTicket}
                    <TextInput label={t('consultingcontainer.hours')} value={formData.hoursCharged}
                        onChange={onHoursFieldChange.bind(this)} />
                    <TextInput label={t('consultingcontainer.hourlyprice')} value={formData.price}
                        onChange={onPriceChange.bind(this)} />
                    {certificateInfo}
                    {salesNumber}
                </div>
            case priceTypes.OnboardingTravel:
                return <div style={{ marginBottom: '6px' }}>
                    {salesforceTicket}
                    <TextInput label={t('consultingcontainer.price')} value={formData.price}
                        onChange={onPriceChange.bind(this)} />
                    <TextInput label={t('consultingcontainer.travelcost')} value={formData.travelCost}
                        onChange={onTravelCostChange.bind(this)} />
                    {certificateInfo}
                    {salesNumber}
                </div>

            case priceTypes.OnboardingTravelVariable:
                return <div style={{ marginBottom: '6px' }}>
                    {salesforceTicket}
                    <TextInput label={t('consultingcontainer.price')} value={formData.price}
                        onChange={onPriceChange.bind(this)} />
                    <TextInput label={t('consultingcontainer.travelcost')} value={formData.travelCost}
                        onChange={onTravelCostChange.bind(this)} />
                    {certificateInfo}
                    {salesNumber}
                </div>

            case priceTypes.OnboardingFixed:
                return <div style={{ marginBottom: '6px' }}>
                    {salesforceTicket}
                    <TextInput label={t('consultingcontainer.price')} value={formData.totalCost}
                        onChange={onPriceChange.bind(this)} />
                    {certificateInfo}
                    {salesNumber}
                </div>
            default:
                return null
        }
    }

    const setPartnerDiscount = (partnerLevel) => {
        if (partnerLevel === 4) {
            commonActions.changeMultiFormField(consultingForm.id, "discount", 20)
        }
    }

    const calculateTotalCost = (formData) => {
        return formData.totalCost - (formData.totalCost * (formData.discount / 100))
    }

    if (!consultingForm || !vbCustomer) {
        return null;
    }


    const formData = consultingForm.data

    const textInputProps = {
        buttonTitle: t('consultingcontainer.usecontactsinfo'),
        label: t('consultingcontainer.attendeename'),
        value: formData.reference,
        onChange: onFieldChange.bind(this, 'reference'),
        buttonClick: () => addContactPerson(vbCustomer)
    }

    var totalCost = calculateTotalCost(formData)

    return <div className="row">
        <PanelGrid columnWidth={6}>
            <div title={t('consultingcontainer.header')} className="form-horizontal">
                <fieldset>
                    <DropDown label={t('consultingcontainer.product')} onChange={onProductSelect.bind(this)} list={consultingProducts} />
                    {renderInputControls()}
                    <TextInput label={t('consultingcontainer.totalcost')} value={helpers.currencyFormatter(totalCost)} disabled={true} onChange={onFieldChange.bind(this, "totalCost")} />
                    {formData.discount && <TextInput label={t('consultingcontainer.discountpercentage')} value={formData.discount} disabled={true} onChange={onFieldChange.bind(this, "discount")} />}
                    <TextInputWithButton {...textInputProps} />
                    <TextInput label={t('consultingcontainer.attendeeemail')} value={formData.attendeeEmail} onChange={onFieldChange.bind(this, "attendeeEmail")} />

                    <div className="form-group padding-top no-left-margin btn-toolbar">
                        <button type="button" className="btn btn-primary" onClick={handleClickSave}>{t('consultingcontainer.save')}</button>
                        <button type="button" className="btn btn-default" onClick={handleClickCancel}>{t('consultingcontainer.cancel')}</button>
                        <Loader />
                    </div>
                </fieldset>
            </div>
        </PanelGrid>
        <PanelGrid columnWidth={6}>
                <CustomerProducts title={t('customerproducts.products')} vbCustomer={vbCustomer} />
        </PanelGrid>
    </div>
}


const priceTypes = {
    FixedPrice: 1,
    VariablePrice: 2,
    HourlyPrice: 3,
    OnboardingFixed: 4,
    OnboardingHourly: 5,
    OnboardingTravel: 6,
    OnboardingTravelVariable: 7
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        consultingForm: state.CommonReducer.forms && state.CommonReducer.forms.consulting,
        consultingProducts: state.CommonReducer.dropdownlists.ConsultingProducts
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConsultingContainer));