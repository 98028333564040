import React, {useEffect} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Card from "../../Components/Card/Card";
import Loader from "../../Components/Loader";
import {bindActionCreators} from "redux";
import customerActions from "../../Actions/customerActions";
import commonActions from "../../Actions/commonActions";
import helpers from "../../Utils/helpers";
import {MainInfoEditContent} from "../../Components/Customer/CustomerComponents/MainInfoEditContent";
import {ActionType} from "../../Constants/actionType";
import {InternalInfoEditContent} from "../../Components/Customer/CustomerComponents/InternalInfoEditContent";
import {useNavigate} from "react-router-dom";
import routes from "../../Constants/routes";
import TargetGroupActions from "../../Actions/Voss/targetGroupActions";

function EditCustomerContainer({ dropdownlists, customerForm, customerActions, commonActions, actionType, t, targetGroupsActions, targetGroups, user, dataLoading }) {
    const navigate = useNavigate();

    useEffect(() => {
        const leadId = helpers.getUrlQueryParameter(window.location, 'leadId');
        const input = helpers.getUrlQueryParameter(window.location, 'input');

        if (!targetGroups)
            targetGroupsActions.getTargetGroups();

        customerActions.beginCustomerCreation(input, leadId);
    }, )

    const navigateAfterAction = () => {
        if (customerForm.data.leadId) {
            return () => navigate(routes.leadEdit(customerForm.data.leadId));
        }
        return () => navigate(routes.search(''));
    }
    const saveAction = () => {
        customerActions.saveVossCustomer(customerForm, ActionType.Create, navigateAfterAction());
    }
    const onCancelClick = () => {
        commonActions.cancelFormEditing(customerForm.id, navigateAfterAction());
    }

    return (<>
            <Card>
        <Card.Header>
            <Card.Header.Title>{`Create new customer: ${customerForm.data.name} (${customerForm.data.organizationNumber})`}</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <form className="container">
                <div className="row">
                    <div className="col">
                        <MainInfoEditContent data={customerForm.data} commonActions={commonActions}
                                             customerActions={customerActions} actionType={actionType} form={customerForm} dataLoading={dataLoading}/>
                    </div>
                    <div className="col">
                        <InternalInfoEditContent data={customerForm.data} dropdownlists={dropdownlists} commonActions={commonActions} actionType={actionType} form={customerForm} targetGroups={targetGroups} user={user}/>
                    </div>
                </div>
            </form>
        </Card.Content>
                {<Card.Footer>
                    <Card.Footer.Buttons>
                        {<>
                            <Loader/>
                            <button type="button" className="btn btn-primary"
                                    onClick={saveAction}>{t("common.save")}</button>
                            <button type="button" className="btn btn-default"
                                    onClick={onCancelClick}>{t("common.cancel")}</button>
                        </>}
                    </Card.Footer.Buttons>
                </Card.Footer>}
            </Card></>)
}
function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customerForm: state.CommonReducer.forms && state.CommonReducer.forms.customerFormData,
        dropdownlists: state.CommonReducer.dropdownlists,
        targetGroups: state.TargetGroupsReducer.targetGroups,
        user: state.CommonReducer.user,
        dataLoading: state.CommonReducer.dataLoading.customerFromBusinessCheck
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        targetGroupsActions: bindActionCreators(TargetGroupActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditCustomerContainer));