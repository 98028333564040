import React, {Component, useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import commonActions from '../../../Actions/commonActions'
import customerActions from '../../../Actions/customerActions'

import withModalForm from '../../../Components/HOC/withModalForm'
import { withTranslation } from 'react-i18next';

import VbProducts from '../../../Components/Customer/VbProducts'
import ProductTerminationModal from '../../../Components/Customer/ProductTerminationModal'
import VbProductDetailsModal from '../../../Components/Customer/VbProductDetailsModal'

import SubscriptionCard from "../../../Components/Card/SubscriptionCard";
import InactiveProducts from '../../../Components/Products/InactiveProducts';


function VbProductsContainer({ vbCustomer, vbProducts, customerActions, reasons, terminationForm, t, payload, isModalActive, onFieldChange, onCloseClick, onOpenModal,dataLoading }) {

    useEffect(() => {
        if (!vbProducts)
            customerActions.getCustomerVBProducts(vbCustomer.customerNumber);
    }, []);

    const getEndDate = (item) => {
        return item.endDate === '0001-01-01' ? 'N/A' : item.endDate;
    }

   const filterProducts = (products, active) => {
        let today = new Date(new Date().toDateString());
        return products?.filter((product) => active ? product.isProductValid || new Date(product.endDate) >= today : (!product.isProductValid && new Date(product.endDate) < today));
    }

        const product = payload;

        var modalAttr = {
            form: terminationForm,
            reasons: reasons,
            onChange: onFieldChange,
            onClose: onCloseClick,
            onLoad: () => customerActions.showCancelVbAgreementModal(product, vbCustomer),
            onSave: () => customerActions.cancelVbAgreement(vbCustomer.customerNumber, terminationForm, () => onCloseClick(terminationForm.id))
        }

        // allowCancelVbAgreement is true for now. Should be solved in backend later
        
        return <>
            <SubscriptionCard 
                title={t("vbproductscontainer.header")}
                activeContent={<VbProducts products={filterProducts(vbProducts, true)} onButtonClick={onOpenModal} allowCancelVbAgreement={true} customer={vbCustomer} getEndDate={getEndDate} />}
                inactiveContent={<InactiveProducts products={filterProducts(vbProducts, false)} getEndDate={getEndDate} />}
                isLoading={dataLoading}
                >
            </SubscriptionCard>
            {isModalActive('terminate') && <ProductTerminationModal {...modalAttr} />}
            {isModalActive('productdetails') && <VbProductDetailsModal onClose={onCloseClick} product={product} />}
        </>
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        reasons: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists['VbTerminationReasons'],
        terminationForm: state.CommonReducer.forms && state.CommonReducer.forms.cancelVbAgreement,
        vbProducts: state.CustomerReducer.vbProducts,
        dataLoading: state.CommonReducer.dataLoading.vbProducts
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default withModalForm(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VbProductsContainer)));