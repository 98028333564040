import { useTranslation } from "react-i18next";
import Card from "../../../Card/Card"
import ApiProduct from "../../../Order/ApiProduct";
import AdditionalUsers from "../../../Order/AdditionalUsers";
import AppointmentOrderDropdown from "../../../../Containers/Appointment/AppointmentOrderDropdown";

export function VbProductOptionsCard({ ...props }) {

    const { t } = useTranslation();

    return <Card>
        <Card.Header>
            <Card.Header.Title>
                {t("vbproductoptions.title")}
            </Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <VbProductOptions {...props} />
        </Card.Content>
    </Card>
}

function VbProductOptions({ orderActions, product, vbCustomer, order }) {

    const { t } = useTranslation();

    const getProductDetails = () => {
        orderActions.getProductDetails(
            product.productId,
            vbCustomer.customerNumber,
            product.additionalUsers,
            product.api,
            product.competitorDiscount,
            order.orderId,
            product.loyaltyDiscountCustomer
        )
    };

    const onApiProductChange = (isChecked) => {
        orderActions.changeApi(isChecked ? 'yes' : 'no');
        getProductDetails();
    };

    const onAdditionalUserChange = (value) => {
        orderActions.changeAdditionalUsersCount(value);
        getProductDetails(product.productId);
    };

    return <div>
        <div className='d-flex flex-column' style={{ gap: "24px" }}>
            <div>
                <h3 className="font-family-ubuntu-semibold mb-12">{t("vbproductoptions.addons")}</h3>
                <ApiProduct product={product} onChange={onApiProductChange} />
            </div>
            <div>
                <h3 className="font-family-ubuntu-semibold mb-12">{t("vbproductoptions.additionalusers")}</h3>
                <AdditionalUsers product={product} onChange={onAdditionalUserChange} />
            </div>
            <AppointmentOrderDropdown appointmentId={product.appointmentId} currentlyInBasket={product.currentlyInBasket} />
        </div>
    </div>
}