import { VossProductGroupType } from "../../../../Constants/VOSS/vossProductGroupType";
import Tooltip, { TooltipContent, TooltipTrigger } from "../../../Overlays/Tooltip";

export function VossTierSelector({...props}) {
    const { product } = props;

    return <div className='d-flex flex-wrap justify-content-center' style={{ gap: "24px" }}>
            {product.offer.tiers.map((tier, index) => (
                <TierCard
                    tier={tier}
                    {...props}
                />
            ))}
        </div>
};

function TierCard({ tier, orderActions, product, vbCustomer, order }) {

    const onTierChange = (tierId) => {
        product.priceCalculation.tierId = tierId;
        orderActions.getVossProductDetails(product.offer.productOfferId, vbCustomer.customerNumber, order.orderId, product.priceCalculation);
    }

    let includedProductGroups = tier.productGroups.filter(x => x.productGroupType == VossProductGroupType.Included);
    let optionalProductGroups = tier.productGroups.filter(x => x.productGroupType != VossProductGroupType.Included);

    let displayedOptionalProductGroups = optionalProductGroups.slice(0, 2);
    let hiddenOptionalProductGroups = optionalProductGroups.slice(2);

    return <div className='card rounded-8 shadow-10 overflow-hidden col-md-3 p-0' style={{ minWidth: "320px" }}>
            <div className={"skynet-product-card-header p-16 text-center"}>
                <h1 className='mb-0 font-family-ubuntu-light'>{tier.name}</h1>
            </div>
            <div className='d-flex flex-column  px-24 pt-24 pb-16 justify-content-between' style={{ height: "400px" }}>
                <div className='d-flex flex-column' style={{ gap: "24px" }}>
                    <div>
                        <p className='bg-primary py-6 px-12 rounded-4 mb-12'>Inkluderar</p>
                        <div className='px-12 d-flex flex-column' style={{ gap: "12px" }}>
                            {includedProductGroups.map((includedProductGroup, index) => (
                                <p className='mb-0'>{includedProductGroup.name}</p>
                            ))}
                        </div>
                    </div>
                    {displayedOptionalProductGroups.length > 0 && <div>
                        <p className='bg-primary py-6 px-12 rounded-4 mb-12'>Valbara tillägg</p>
                        <div className='px-12 d-flex flex-column' style={{ gap: "12px" }}>
                            {displayedOptionalProductGroups.map((optionalProductGroup, index) => (
                                <p className='mb-0 border-bottom'>{optionalProductGroup.name}</p>
                            ))}
                            {hiddenOptionalProductGroups.length > 0 &&
                                <Tooltip>
                                    <TooltipTrigger>
                                        {<>
                                            <p className='mb-0 text-center text-underline'>Visa {hiddenOptionalProductGroups.length} till</p>
                                        </>}
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <>
                                            {hiddenOptionalProductGroups.map((hiddenOptionalProductGroups, index) => (
                                                <p className='mb-0'>{hiddenOptionalProductGroups.name}</p>
                                            ))}
                                        </>
                                    </TooltipContent>
                                </Tooltip>}
                        </div>
                    </div>}
                </div>
                <div>
                    <button className='btn btn-primary w-100 mb-0' onClick={() => onTierChange(tier.id)}>Välj</button>
                </div>
            </div>
        </div>
}