import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../Card/Card';
import helpers from '../../../../Utils/helpers';
import RadioButtonsInline from '../../../../Components/RadioButtonsInline';
import DateTimeInput from '../../../../Components/FormComponents/Input/DateTimeInput';
import AdditionalUsers from '../../../Order/AdditionalUsers';
import Checkbox from '../../../Checkbox';
import * as calcTypes from '../../../../Constants/priceCalculationTypes';
import RadioButton from '../../../RadioButton';
import TextInput from '../../../FormComponents/Input/TextInput';
import AppointmentOrderDropdown from '../../../../Containers/Appointment/AppointmentOrderDropdown';
import { hasReadWriteAccess } from '../../../../Utils/permissionUtils';
import { UserRoles } from '../../../../Constants/userRoles';
import moment from 'moment';

export function VonProductOptionsCard({ ...props }) {

    const { t } = useTranslation();

    return <Card>
        <Card.Header>
            <Card.Header.Title>
                {t("vonproductoptions.title")}
            </Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <VonProductOptions {...props} />
        </Card.Content>
    </Card>
}


function VonProductOptions({ user, product, vbCustomer, orderActions, calcType, order }) {

    const { t } = useTranslation();
    
    const [calculations, setCalculations] = useState([]);

    const onPeriodChange = (period) => {
        const addons = getCheckedAddons();
        orderActions.getVonProductDetails(product.productNumber, vbCustomer.customerNumber, period, order.orderId, product.additionalUsersCount, addons, true, product.invoiceStartDate);
    };

    const onAddonCheck = (element) => {
        let checkedAddons = getCheckedAddons();

        if (element.checked) {
            checkedAddons.push(element.value);
        } else {
            const index = checkedAddons.indexOf(element.value);

            if (index > -1) {
                checkedAddons.splice(index, 1);
            }
        }

        orderActions.updateAddons(checkedAddons);

        orderActions.getVonProductDetails(product.productNumber, vbCustomer.customerNumber, product.period, order.orderId, product.additionalUsersCount, checkedAddons, true, product.invoiceStartDate);
    };

    const getCheckedAddons = () => {
        return product.addons
            .filter(addon => addon.isChecked)
            .map(addon => addon.productId);
    };

    const onAdditionalUserChange = (value) => {
        orderActions.changeAdditionalUsersCount(value);
        const addons = getCheckedAddons();

        orderActions.getVonProductDetails(product.productId, vbCustomer.customerNumber, product.period, order.orderId, product.additionalUsersCount, addons, true, product.invoiceStartDate);
    };
    const onDateChange = (date) => {
        const addons = getCheckedAddons();

        product.invoiceStartDate = date;
        orderActions.getVonProductDetails(product.productNumber, vbCustomer.customerNumber, product.period, order.orderId, product.additionalUsersCount, addons, true, date);
    };

    const onChangeCostCalculation = (cost, id) => {
        const updatedCalculations = [...calculations];
        updatedCalculations[id] = { ...updatedCalculations[id], cost };
        setCalculations(updatedCalculations);
    };


    const onChangeNumberOfEmployees = (id, employees) => {
        const updatedCalculations = [...calculations];
        updatedCalculations[id] = { ...updatedCalculations[id], employees };
        setCalculations(updatedCalculations);
    };

    const getCalculatedPrice = (id) => {
        if (calculations[id] !== undefined) {
            if (calculations[id].employees !== undefined && calculations[id].cost !== undefined) {
                return helpers.currencyFormatter(calculations[id].employees * calculations[id].cost);
            }
        }
        return helpers.currencyFormatter(0);
    };

    return <div className='d-flex flex-column' style={{ gap: "24px" }}>
        {(user && hasReadWriteAccess(user, UserRoles.SMBSales)) && (calcType === calcTypes.priceCalculationTypes.order) && <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vonproductoptions.invoicestartdate")}</h3>
            <div className='col-9 p-0'>
                <DateTimeInput dateFormat={"YYYY-MM"} showTime={false} field="invoiceStartDate" closeOnSelect={true} onChange={(value) => onDateChange(value)} value={product.invoiceStartDate} placeholderText={moment().format('YYYY-MM-DD')} />
            </div>
        </div>}
        <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vonproductoptions.renewperiod")}</h3>
            <RadioButtonsInline name="periods" column={true} values={product.periods} currentChecked={product.period} onChange={onPeriodChange} disabled={product.readonlyPeriods} affix={" månader"} />
        </div>
        {product?.usesAdditionalLicenses !== undefined && product?.usesAdditionalLicenses && <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vonproductoptions.additionalusers")}</h3>
            <AdditionalUsers product={product} onChange={onAdditionalUserChange} />
        </div>}
        {product.addons && product.addons.length > 0 &&
            <div>
                <h3 className="font-family-ubuntu-semibold mb-12">{t("vonproductoptions.addons")}</h3>
                {product.addons.map((addon, index) => {
                    if (vbCustomer && vbCustomer.typeOfBusiness === 2 && addon.productNumber === "Lime.annualreport")
                        return;
                    if (vbCustomer && vbCustomer.typeOfBusiness !== 2 && addon.productNumber === "Lime.standard")
                        return;
                    return <div key={index} className='mb-10'>
                        <Checkbox label={addon.productDescription} value={addon.productId} checked={addon.isChecked} disabled={addon.isDisabled} onChange={onAddonCheck} />
                    </div>
                })}
            </div>
        }
        {product.productCostCalculations.map((productCostCalculation, index) => {
            return <div className="form-group mb-0" key={index}>
                <h3 className='font-family-ubuntu-semibold mb-12'>{productCostCalculation.calculationName}</h3>
                <div className="form-group mb-0">
                    <div className="">
                        {productCostCalculation.calculationCosts.map((calculationCost) => {
                            return <div> <RadioButton style={{ marginRight: 5, marginTop: 4 }} group={productCostCalculation.id} label={calculationCost.costName} value={calculationCost.price} onChange={(value) => onChangeCostCalculation(value, productCostCalculation.id)} /> </div>
                        })}
                        <div className='col-9 p-0'>
                            <TextInput placeholder={0} label={t("vonproductoptions.noofemployees")} value={calculations[productCostCalculation.id] != undefined ? calculations[productCostCalculation.id].employees : 0} onChange={(e) => onChangeNumberOfEmployees(productCostCalculation.id, e.target.value)} />
                        </div>
                        <p className='mb-8'>{t("vonproductoptions.price")}</p>
                        <p className='mb-0'>{getCalculatedPrice(productCostCalculation.id)}</p>
                    </div>
                </div>
            </div>
        })}
        <AppointmentOrderDropdown appointmentId={product.appointmentId} currentlyInBasket={product.currentlyInBasket} />
    </div>
}