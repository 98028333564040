import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import routes from '../../Constants/routes'

import customerActions from '../../Actions/customerActions'

import helpers from '../../Utils/helpers'

import CollaborationsList from '../../Components/Customer/CollaborationsList'
import CollaborationVictoryBar from '../../Components/Customer/CollaborationVictoryBar';
import commonActions from '../../Actions/commonActions';
import { useNavigate } from 'react-router-dom';
import Panel from "../../Components/Panel";
import BadgeCounter from "../../Components/BadgeCounter";
import {DefaultCard} from "../../Components/Card/Card";

function CollaborationsContainer({
    t,
    collaborations,
    filteredCollaborations,
    vbCustomer,
    customer,
    grouped,
    customerActions,
    commonActions
}) {

    const [searchInput, setSearchInput] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [i, setI] = useState(-1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [latestYear, setLatestYear] = useState(1);
    const [oldestYear, setOldestYear] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        let controller = new AbortController();
        if (!vbCustomer.hasVonAccount) {
            navigate(routes.customerNewVonAccount(customer.pathId));
        }
        else {
            customerActions.getCollaborations(vbCustomer.customerNumber, controller);
        }
        return () => { 
            controller.abort();
            grouped = [];
            customerActions.clearCollaborations();
        }

    }, []);

    useEffect(() => {
        getOldest();
        getYoungest();
    }, [grouped]);

    const renderNoData = () => {
        return (<p>{t('collaborationscontainer.noentries')}</p>);
    }

    const onChange = (event) => {
        setSearchInput(event.target.value);
    }

    const filterCollaborations = (collaborations) => {
        if (!/\S/.test(searchInput)) {
            return collaborations;
        }

        if (isNaN(searchInput)) {
            return helpers.filterObjectArrayByValue(collaborations, "name", searchInput)
        }

        return helpers.filterObjectArrayByValue(collaborations, "customerNumber", searchInput)
    }

    const groupCollaborations = (collaborations) => {
        return collaborations.reduce((result, collaboration) => {
            const product = collaboration.product || 'n/a'
            result[product] = result[product] || [];
            result[product].push(collaboration);

            return result;
        }, Object.create(null));
    }

    const groupModules = (products) => {
        let result = []

        products.map((collab, index) => {
            if (result.some(result => result.key === collab.moduleName)) {
                let index = result.findIndex(key => key.key === collab.moduleName);
                result[index].value++
            }
            else
                result.push({ key: collab.moduleName, value: 1 });
        });

        return result
    }

    const getOldest = () => {
        if (grouped && grouped.length) {
            let oldest = grouped.reduce((prev, curr) => curr.year > prev.year ? curr : prev).year;
            setLatestYear(oldest);
        }
    }
    const getYoungest = () => {
        if (grouped && grouped.length) {
            let youngest = grouped.reduce((prev, curr) => curr.year < prev.year ? curr : prev).year;
            setOldestYear(youngest);
        }
    }
    const incrementYear = () => {
        if (year < latestYear) {
            setYear(year + 1)
        }
    }
    const decrementYear = () => {
        if (year > oldestYear) {
            setYear(year - 1)
        }
    }
    const isNextButtondisabled = () => {
        return year + 1 > latestYear
    }
    const isPreviousButtondisabled = () => {
        return year - 1 < oldestYear
    }

    const groupedChartResult = (serviceName, year) => {

        const groups = grouped.filter(x => x.serviceName === serviceName && x.year === year)
        const result = [];

        groups.map(item => {
            result.push({
                x: item.nameOfMonth,
                y: item.count,
                label: item.count,
                accumulated: item.accumulated
            });
        });

        return result;
    }

    const renderCollaborationDiagram = () => {
        if (!grouped || !grouped.length)
            return <></>
        
        return <>
            <button className="btn btn-default" disabled={isPreviousButtondisabled()} onClick={() => decrementYear()}>Förregående år</button>
            <button className="btn btn-default" disabled={isNextButtondisabled()} onClick={() => incrementYear()}>Nästa år</button>
            <div className="row">
                {serviceNames.map((item, i) => {
                        const data = groupedChartResult(item, year);

                        if (!data.length)
                            return null;

                        return <div className="col-lg-6 col-xs-12" key={i}>
                            <div className="victorybar">
                                <CollaborationVictoryBar data={data} serviceName={item} year={year}/>
                            </div>
                        </div>;
                    }
                )}
            </div>
        </>
    }

    const sortListByKey = (listName, list) => {
        return key => {
            commonActions.sortListByKey(list, key, listName);
        }
    }

    if (!collaborations || !grouped) {
        return null;
    }

    if (collaborations.length === 0) {
        //return renderNoData();
    }

    filteredCollaborations = filterCollaborations(filteredCollaborations);
    const groupedCollaborations = groupCollaborations(collaborations);
    const serviceNames = [...new Set(grouped.map(x => x.serviceName))]
    const showConsultant = filteredCollaborations.findIndex(key => key.customerNumber === vbCustomer.customerNumber) >= 0;

    return (
        <DefaultCard title={t('collaboration.header')}>
                <fieldset>
                    <input placeholder={t('collaborationscontainer.searchfield')} className="form-control" type="text" autoFocus value={searchInput} onChange={onChange.bind(this)} />
                </fieldset>
            
            <div className="group-list">
            {Object.keys(groupedCollaborations).sort().map((product, index) => {
                
                return <details key={index} className="collapse-list">
                    <summary className="collapse-list-header">
                        <div className="collapse-list-title d-flex align-items-center" style={{justifyContent: "space-between"}} >
                            <span>
                                {product} <BadgeCounter badgeNumber={groupedCollaborations[product].length} />
                            </span>
                            <span className="caret"></span>
                        </div>
                    </summary>
                    <div className="collapse-list-content">
                        {groupModules(groupedCollaborations[product]).map((collabCount, index) => {

                                 return <div key={index} className="collapse-list-content">

                                     <details className="collapse-list">
                                         <summary className="collapse-list-header">
                                             <div className="collapse-list-title">
                                                 <span className="caret"></span>
                                                 {!!collabCount.key ? collabCount.key + " ": product + " "}
                                                 <BadgeCounter badgeNumber={collabCount.value} />
                                             </div>
                                         </summary>
                                         <div className="collapse-list-content">
                                             
                                             <table className="table table-stripe table-condensed">
                                                 <thead>
                                                 <tr>
                                                    {showConsultant ? 
                                                        <>
                                                        <th>{t('collaboration.consultantnumber')}</th>
                                                        <th>{t('collaboration.consultantname')}</th>
                                                        </> : 
                                                        <>
                                                        <th>{t('collaboration.customernumber')}</th>
                                                        <th>{t('collaboration.customername')}</th>
                                                        </>
                                                    }                                                   
                                                     <th>{t('collaboration.invoicereceiver')}</th>
                                                     <th>{t('created')}</th>
                                                 </tr>
                                                 </thead>
                                                 <tbody>
                                             
                                                 {groupedCollaborations[product].filter(t => t.moduleName === collabCount.key).map((collab, index) => {
                                                     
                                                     return <tr key={index}>
                                                    {showConsultant ? 
                                                        <>
                                                        <td>{collab.consultantNumber} </td>
                                                        <td>{collab.consultantName} </td>
                                                        </> : 
                                                        <>
                                                        <td>{collab.customerNumber} </td>
                                                        <td>{collab.name} </td>
                                                        </>
                                                    }     
                                                     <td>{collab.invoiceParty}</td>
                                                     <td>{helpers.formatDate(collab.created)}</td>
                                                     </tr>
                                                     
                                                 })}
                                                 </tbody>
                                             </table>
                                                 
                                         </div>
                                     </details>
                                 </div>
                        })}
                    </div>
                </details>
            })}
            </div>
            <CollaborationsList collaborations={filteredCollaborations} sortListByKey={sortListByKey("filteredCollaborations", filteredCollaborations)} listOfServiceNames={serviceNames} showConsultant={showConsultant} />
            {renderCollaborationDiagram()}
        </DefaultCard>
    );
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        collaborations: state.CustomerReducer.collaborations,
        filteredCollaborations: state.CustomerReducer.filteredCollaborations,
        grouped: state.CustomerReducer.collaborationsGrouped
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CollaborationsContainer));