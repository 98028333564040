import { useTranslation } from 'react-i18next';

export default function CustomerPurchaseHistory({ vbProducts, vonProducts }) {

    const { t } = useTranslation();

    const isActive = (product) => {
        const currentDate = new Date();
        const purchaseDate = new Date(product.purchaseDate);
        const endDate = product.endDate ? new Date(product.endDate) : null;

        return endDate ? (currentDate >= purchaseDate && currentDate <= endDate) : true;
    };

    const activeOnPremProducts = vbProducts?.filter(product => isActive(product));
    const inactiveOnPremProducts = vbProducts?.filter(product => !isActive(product));
    const activeSaasProducts = vonProducts?.filter(product => isActive(product));
    const inactiveSaasProducts = vonProducts?.filter(product => !isActive(product));

    return (
        <div className="rounded-8 bg-primary customer-purchase-history-container">
            <div className="card-heading">
                <h3 className="card-title font-family-ubuntu-semibold">{t('customerpurchasehistory.customerpurchasehistory')}</h3>
            </div>
            <div className="card-body p-24">
                {(vbProducts?.length > 0 || vonProducts?.length > 0) ? <div className="d-flex flex-column" style={{ gap: "24px" }}>
                    {vbProducts?.length > 0 && <div>
                        {activeOnPremProducts?.length > 0 && <div>
                            <h3 className="font-family-ubuntu-semibold mb-16 text-success">OnPrem - {t('customerpurchasehistory.activesubscriptions')}</h3>
                            {activeOnPremProducts?.map((product) => {
                                return (
                                    <div key={product.id}>
                                        <p className='mb-0'>
                                            <span className='font-family-ubuntu-semibold'>{product.product}</span> ∙ {product.purchaseDate} - {product.endDate}
                                        </p>
                                        <p className='mb-16'>{t('customerpurchasehistory.users')}: {product.users}</p>
                                    </div>
                                );
                            })}
                        </div>}
                        {inactiveOnPremProducts?.length > 0 && <div>
                            <h3 className="font-family-ubuntu-semibold mb-16 text-error">OnPrem - {t('customerpurchasehistory.inactivesubscriptions')}</h3>
                            {inactiveOnPremProducts?.map((product) => {
                                return (
                                    <div key={product.id}>
                                        <p className='mb-0'>
                                            <span className='font-family-ubuntu-semibold'>{product.product}</span> ∙ {product.purchaseDate} - {product.endDate}
                                        </p>
                                        <p className='mb-16'>{t('customerpurchasehistory.users')}: {product.users}</p>
                                    </div>
                                );
                            })}
                        </div>}
                    </div>}
                    {vonProducts?.length > 0 && <div>
                        {activeSaasProducts?.length > 0 && <div>
                            <h3 className={`font-family-ubuntu-semibold mb-16 text-success`}>SAAS - {t('customerpurchasehistory.activesubscriptions')}</h3>
                            {activeSaasProducts?.map((product) => {
                                return (
                                    <div key={product.id}>
                                        <p className='mb-0'>
                                            <span className='font-family-ubuntu-semibold'>{product.product}</span> ∙ {product.purchaseDate} - {product.endDate}
                                        </p>
                                        <p className='mb-16'>{t('customerpurchasehistory.module')}: {product.module}</p>
                                    </div>
                                );
                            })}
                        </div>}
                        {inactiveSaasProducts?.length > 0 && (
                            <div>
                                <h3 className={`font-family-ubuntu-semibold mb-16 text-error`}>SAAS - {t('customerpurchasehistory.inactivesubscriptions')}</h3>
                                {inactiveSaasProducts?.map((product) => (
                                    <div key={product.id}>
                                        <p className='mb-0'>
                                            <span className='font-family-ubuntu-semibold'>{product.product}</span> ∙ {product.purchaseDate} - {product.endDate}
                                        </p>
                                        <p className='mb-16'>{t('customerpurchasehistory.module')}: {product.module}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>}
                </div> : <p><em>{t('customerpurchasehistory.nohistoryfound')}</em> ¯\_(ツ)_/¯</p>}
            </div>
        </div>
    );
}