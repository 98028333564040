import {useTranslation, withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Modal from "../../Modal";
import {MainInfoEditContent} from "./MainInfoEditContent";
import {InternalInfoEditContent} from "./InternalInfoEditContent";
import {ActionType} from "../../../Constants/actionType";
import {bindActionCreators} from "redux";
import commonActions from "../../../Actions/commonActions";
import customerActions from "../../../Actions/customerActions";
import {connect} from "react-redux";
import TargetGroupActions from "../../../Actions/Voss/targetGroupActions";

function EditCustomerModal ({form, onCloseClick, customer, commonActions, customerActions, dropdownlists, actionType, user, dataLoading, targetGroupsActions, targetGroups }) {
    const {t} = useTranslation();

    useEffect(() => {
        if (!form)
            customerActions.getSelectedCustomerForEdit(customer.vbCustomer.customerNumber, "customerFormData");

        if (!targetGroups)
            targetGroupsActions.getTargetGroups();
    }, []);

    const modalProps = {
        title: `${t("editcustomermodal.editcustomer")} ${customer?.vossCustomer?.name ?? customer?.vbCustomer?.name}`,
        stateful: true,
        open: true,
        bodyHeight: 200,
        onCancelClick: () => onCloseClick(form.id),
        onSaveClick: () => customerActions.saveVossCustomer(form, ActionType.Edit, onCloseClick),
        mainButtonTitle: t("common.save"),
        customClassName: "modal-xl",
        showButtons: !dataLoading
    }

    return <Modal {...modalProps}>
        {dataLoading || !form ? <div className="spinner spinner-default-green m-auto d-block"/> :
            <form className="skynet-form container">
            <div className="row">
                <div className="col-6">
                    <MainInfoEditContent commonActions={commonActions}
                                         customerActions={customerActions}
                                         form={form} actionType={actionType}
                                         user={user}
                    />
                </div>
                <div className="col-6">
                    <InternalInfoEditContent
                        dropdownlists={dropdownlists}
                        commonActions={commonActions}
                        form={form}
                        actionType={actionType}
                        targetGroups={targetGroups}
                        user={user}/>
                </div>
            </div>
        </form>}
    </Modal>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        user: state.CommonReducer.user,
        form: state.CommonReducer.forms && state.CommonReducer.forms.customerFormData,
        dropdownlists: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists,
        dataLoading: state.CommonReducer.dataLoading.editCustomer,
        targetGroups: state.TargetGroupsReducer.targetGroups
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch),
        targetGroupsActions: bindActionCreators(TargetGroupActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditCustomerModal));
